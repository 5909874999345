






















































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import OrderCard from '@/components/cards/OrderCard.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'
import TableFooter from '@/components/TableFooter.vue'

// INTERFACES
import { ApplicationTabName, CountResource, NameValueResource, PaginationMeta, RequestsCarParkIdGetParams, RequestShortResource } from '@/store/types'

// STORE
import FleetApplicationsModule from '@/store/modules/fleet/applications'
import MechanicApplicationsModule from '@/store/modules/mechanic/applications'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

function validateTabName (to: any, next: any) {
  const isAll = to.params.tab === 'all'
  const isDrivers = to.params.tab === 'driver'
  const isManagers = to.params.tab === 'manager'

  if ( isAll || isDrivers || isManagers) {
    const tabName = to.params.tab as ApplicationTabName
    const entity = +to.params.entity

    FleetApplicationsModule.getTableData({ entity, tabName })

    next()
  } else {
    const entity = to.params.entity
    const role = to.params.role

    next({ name: 'orders', params: { role, entity, tab: 'all' } })
  }
}

@Component({
  components: {
    OrderCard,
    CaptionCard,
    FiltersPanel,
    TableFooter,
  },
  beforeRouteEnter (to, from, next) {
    validateTabName(to, next)
  },
  beforeRouteUpdate (to, from, next) {
    validateTabName(to, next)
  },
})
export default class FleetOrders extends Mixins(SystemMixin, NotifyMixin) {
  // Список табов
  readonly TABS: NameValueResource[] = [
    {
      name: 'Все заявки',
      value: 'all',
    },
    {
      name: 'От водителей',
      value: 'driver',
    },
    {
      name: 'От менеджеров',
      value: 'manager',
    },
  ]

  private isReady = false
  private isNoData = false

  private handlerDownload () {
    window.open(this.excelLink)
  }

  private get isEmpty (): boolean {
    return !this.tableData || !this.tableData.length
  }

  // NOTE: Эта переменная используется для выбора источника данных
  private get tabName (): ApplicationTabName {
    return this.$route.params.tab as ApplicationTabName
  }

  // NOTE: Эта переменная используется для отправки в стор
  private get payload (): { entity: number, tabName: ApplicationTabName } {
    return { entity: +this.entity, tabName: this.tabName }
  }

  private get tableData (): RequestShortResource[] {
    return this.role === 'fleet' ?
      FleetApplicationsModule[`${this.tabName}Applications`].data :
      MechanicApplicationsModule['allApplications'].data
  }

  private get statuses (): CountResource[] {
    return this.role === 'fleet' ?
      FleetApplicationsModule[`${this.tabName}Statuses`] :
      MechanicApplicationsModule['allStatuses']
  }

  private get paginator (): PaginationMeta {
    return this.role === 'fleet' ?
      FleetApplicationsModule[`${this.tabName}Applications`].meta :
      MechanicApplicationsModule['allApplications'].meta
  }

  private get filters (): RequestsCarParkIdGetParams {
    return this.role === 'fleet' ?
      FleetApplicationsModule[`${this.tabName}Filters`] :
      MechanicApplicationsModule['allFilters']
  }

  private set filters (value: RequestsCarParkIdGetParams) {
    if (this.role !== 'mechanic') {
      FleetApplicationsModule.setTableFilters({
        tabName :this.tabName,
        data: value,
      })
      FleetApplicationsModule.getTableData(this.payload)
    } else {
      MechanicApplicationsModule.setTableFilters({
        tabName: 'all',
        data: value,
      })
      MechanicApplicationsModule.getTableData(this.payload)
    }
  }

  private get activeStatus (): string | undefined {
    return this.role === 'fleet' ?
      FleetApplicationsModule[`${this.tabName}Filters`].status :
      MechanicApplicationsModule['allFilters'].status
  }

  private set activeStatus (value: string | undefined) {
    if (this.role !== 'mechanic') {
      FleetApplicationsModule.setActiveStatus( { tabName: this.tabName, data: value })
      FleetApplicationsModule.getTableData(this.payload)
    }else {
      MechanicApplicationsModule.setActiveStatus({ tabName: 'all', data: value })
      MechanicApplicationsModule.getTableData(this.payload)
    }
  }

  private get excelLink (): any {
    return this.role === 'fleet' ?
      FleetApplicationsModule[`${this.tabName}Applications`].xlsxRoute :
      MechanicApplicationsModule['allApplications'].xlsxRoute
  }

  private mounted () {
    if (this.role !== 'mechanic') {
      FleetApplicationsModule.resetTableFilters(this.tabName)

      FleetApplicationsModule.getTableData(this.payload)
        .finally(() => {
          this.isReady = true
          this.isNoData = !this.tableData || !this.tableData.length
        })
      FleetApplicationsModule.getTableStatuses(this.payload)
    } else {
      MechanicApplicationsModule.resetTableFilters('all')
      MechanicApplicationsModule.getTableData(this.payload)
        .finally(() => {
          this.isReady = true
          this.isNoData = !this.tableData || !this.tableData.length
        })
      MechanicApplicationsModule.getTableStatuses(this.payload)
    }
  }

  private handleChangeTab () {
    this.isReady = false
    FleetApplicationsModule.getTableData(this.payload)
      .finally(() => {
        this.isReady = true
      })
    FleetApplicationsModule.getTableStatuses(this.payload)
  }

  private beforeDestroy () {
    if (this.role !== 'mechanic') {
      FleetApplicationsModule.resetTableFilters(this.tabName)
    } else {
      MechanicApplicationsModule.resetTableFilters('all')
    }
  }
}
